
const MainPage = (props) => {
return (
<>
<div class="layout-wrapper">
 
   {props.children}
  
</div>
</>
)
}
export default MainPage;