import { Link } from "react-router-dom";
import logowblue from "../../assets/images/logo-blue.png";
import React, { useState, useEffect } from "react";
import host from "../../host";
// import Marquee from "react-fast-marquee";
export default function HeaderPage() {
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const handleScroll = () => {
    if (window.pageYOffset > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  return (
    <div>
      <header className={isScrolled ? "fixed" : ""}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="inner-header">
                <div className="logo-box">
                  <div className="logo">
                    <Link to="/">
                      <img className="logowhite" src={logowblue} alt="" />
                      <img className="logoblue" src={logowblue} alt="" />
                    </Link>
                  </div>
                </div>
                <div className="right-actions">
                  <div className="col-buttons">
                    <Link to={host.sso+'?redirectUrl='+process.env.REACT_APP_SKILLWISE_DOMAIN+'/home&loginUrl='+process.env.REACT_APP_SKILLWISE_DOMAIN+'/sign-in/token'}> Login </Link>

                    <a
                      href={host.selectright_marketing + "/book-demo"}
                      className=" btn btn-primary   "
                      type="button"
                    >
                      Book a Demo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}
