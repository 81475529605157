import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import HeaderPage from '../_layout/elements/header';
import FooterPage from '../_layout/elements/footer';

export const Terms = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
  };

  return (
    <div>
 <HeaderPage />
 <section className="minibanner  termsbanner" >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="section-title text-center  wow zoomIn mb-0" data-wow-duration="1.6s" style={{visibility: 'visible', animationDuration: '1.6s', animationName: 'zoomIn'}}>
                <h2>Terms and Conditions </h2>
                <p>These Terms and Conditions help define skillwise relationship with you as you interact with our services.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" termsandcondition section-pedding  ">
        <div className="container">
          <div className="row  ">
            <div className="col-12">
              <div className="occasion-tab">
                <ul className="nav nav-tabs  ">
                  <li className="nav-item">
                    <a className="nav-link active" data-bs-toggle="tab" href="#privacypolicy"> Privacy Policy </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#termscondition"> Terms &amp; Conditions </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" data-bs-toggle="tab" href="#refundpolicy"> Refund &amp; Cancellation Policy </a>
                  </li>
                </ul>
              </div>
              <div className="tab-content">
                <div className="tab-pane fade show active " id="privacypolicy">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card    ">
                        <div className="card-body">
                          <div className="terms-content">
                            <p>Skillwise.ai is committed to protecting the privacy of our users. This privacy policy explains how we collect, use and disclose personal information from users of the Platform.</p>
                            <h4>Collection of Personal Information</h4>
                            <p>We may collect personal information from users of the Platform such as name, email address and other contact information. We may also collect other information, such as test results and performance data. This information is collected when users register for an account on the Platform, take a test, or otherwise interact with the Platform.</p>
                            <h4>Use of Personal Information</h4>
                            <p>We use personal information collected from users of the Platform to provide, improve, and personalize the Platform's services. We may also use personal information for research and development, and to contact users with updates, offers, or other information related to the Platform.</p>
                            <h4>Disclosure of Personal Information</h4>
                            <p>We will not sell, rent, or otherwise disclose personal information collected from users of the Platform to third parties without the user's consent, except in the following circumstances:</p>
                            <p>To comply with legal obligations, such as a subpoena or court order
                              To protect the rights, property, or safety of the Platform, its users, or the public
                              In the event of a merger, acquisition, or other corporate transaction
                              Security of Personal Information
                            </p>
                            <p>We take reasonable measures to protect personal information collected from users of the Platform from unauthorized access, use, or disclosure. However, no method of transmission over the internet, or method of electronic storage, is 100% secure. Therefore, we cannot guarantee the absolute security of personal information.</p>
                            <h4>Changes to this Policy</h4>
                            <p>We may update this Policy from time to time. If we make changes to this Policy, we will notify users by updating the date of this Policy and posting the updated Policy on the Platform.</p>
                            <h4>Contact Us</h4>
                            <p>If you have any questions or concerns about this Policy, or about how we handle personal information, please contact us at sales@ngnext.tech.</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="termscondition">
                  <div className="card">
                    <div className="card-body">
                      <div className="terms-content">
                        <p>Welcome to our online test platform Skillwise.ai. By accessing or using the Platform, you agree to be bound by the following terms and conditions. If you do not agree to these Terms, please do not use the Platform.</p>
                        <h4>Use of the Platform</h4>
                        <p>You are granted a limited, non-transferable, non-exclusive license to access and use the Platform for personal, non-commercial use. You may not use the Platform for any other purpose, or in any manner that is inconsistent with these Terms.</p>
                        <p>
                          You are responsible for maintaining the confidentiality of your account and password, and for all activities that occur under your account. You agree to immediately notify us of any unauthorized use of your account or password.
                        </p>
                        <h4>Content and Intellectual Property</h4>
                        <p>The Platform, including all text, images, graphics, software, and other content, is protected by copyright, trademark, and other intellectual property laws. You may not copy, reproduce, republish, upload, post, transmit, or distribute any content from the Platform without our prior written consent.</p>
                        <p>We retain all rights, title, and interest in and to the Platform and its content. You acknowledge that you do not acquire any ownership rights by using the Platform.</p>
                        <h4>Tests and Test Results</h4>
                        <p>The Platform provides online tests and test results. You are solely responsible for ensuring that you meet any prerequisites or qualifications required to take a particular test.</p>
                        <p>
                          Test results are for your personal use only and should not be used for any commercial purpose or shared with any third party. We cannot guarantee the accuracy or completeness of test results, and we will not be liable for any errors or omissions in the test results.
                        </p>
                        <h4>Disclaimers</h4>
                        <p>THE PLATFORM IS PROVIDED "AS IS" AND "AS AVAILABLE." WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT.</p>
                        <p>WE WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND, INCLUDING BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES, ARISING FROM THE USE OF THE PLATFORM.</p>
                        <h4>Indemnification</h4> 
                        <p>You agree to indemnify and hold us harmless from any claims, losses, damages, liabilities, and expenses arising from your use of the Platform, your violation of these Terms, or your violation of any rights of another.</p>
                        <h4>Governing Law</h4>
                        <p>These Terms shall be governed by and construed in accordance with the laws and jurisdiction of the Muzaffarpur, Bihar, India, without giving effect to any principles of conflicts of law.</p>
                        <h4>Entire Agreement</h4>
                        <p>These Terms constitute the entire agreement between you and us regarding the use of the Platform. If any provision of these Terms is found to be invalid or unenforceable, the remaining provisions will remain in full force and effect.</p>
                        <h4>Changes to these Terms</h4>
                        <p>We may update these Terms from time to time. If we make changes to these Terms, we will notify you by updating the date of these Terms and posting the updated Terms on the Platform.</p>
                        <h4>Contact Us</h4>
                        <p>If you have any questions or concerns about these Terms, please contact us at  <Link to={'mailto:sales@ngnext.tech'}> sales@ngnext.tech </Link> </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="refundpolicy">
                  <div className="card">
                    <div className="card-body">
                      <div className="terms-content">
                        <p>Our test platform skillwise.ai is committed to providing a positive experience for our users. However, we understand that there may be circumstances where a user may wish to request a refund or cancel a test. This policy explains our refund and cancellation policy for the Platform.</p>
                        <h4>Refunds and Cancellations</h4>
                        <p>There will be no refund in case of pre-paid subscriptions.</p>
                        <p>   In case of post-paid subscriptions, no amount will be charged for the unused credits if any is left.</p>
                        <h4> Changes to this Policy</h4>
                        <p>We reserve the right to make changes to this Policy at any time. If we make changes to this Policy, we will notify users by updating the date of this Policy and posting the updated Policy on the Platform.</p>
                        <h4> Contact Us</h4>
                        <p>If you have any questions or concerns about this Policy, or about requesting a refund or cancelling a test, please contact us at sales@ngnext.tech .</p>
                        <p>By using this platform, you agree to these terms and conditions and are bound by them. If you don't agree to the terms, please discontinue the use of this platform</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




      <FooterPage />
    </div>
  )
}