import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Lottie from "lottie-react";
import { Pagination } from "swiper";
import HeaderPage from "../_layout/elements/header";
import FooterPage from "../_layout/elements/footer";
import "swiper/css/pagination";
import "swiper/css";
import torch from "../assets/images/torch.svg";
import workforce from "../assets/images/workforce.gif";
import proctoring from "../assets/images/proctoring.gif";
import administer from "../assets/json/administer.json";
import testall from "../assets/images/testall.gif";
import Library from "../assets/images/from-library.gif";
import comingsoon from "../assets/images/coming-soon3.png";
import Custome from "../assets/json/Custome.json";
import mcqsingal from "../assets/json/MCQ Single 7.json";
import mcqmultiple from "../assets/json/MCQ Multiple 7.json";
import tita from "../assets/json/TITA 7.json";
import Essay from "../assets/json/Essay Test 7.json";
import video from "../assets/json/Video Test 7.json";
import coding from "../assets/json/Code 7.json";
import webcam from "../assets/json/Webcam.json";
import audioproctoring from "../assets/json/Waveform.json";
import tabswitch from "../assets/json/Tab switiching 1.json";
import copypast from "../assets/json/Copy Paste.json";
import geolocation from "../assets/json/Location.json";
import dashboard1 from "../assets/json/Dashboard 1.json";
import dashboard2 from "../assets/json/Dashboard 2.json";
import dashboard3 from "../assets/json/Dashboard 3.json";
import Overall from "../assets/json/Overall Percentage and Percentile 7.json";
import Strengths from "../assets/json/Strengths and Weaknesses 7.json";
import time from "../assets/json/Time Taken 7.json";
import Frauds from "../assets/json/Fraud Report 7.json";
import Certificates from "../assets/json/Credible and Automated Certificates 7.json";
import settime from "../assets/json/timelimite.json";
import livemonitoring from "../assets/json/Monitoring.json";
import brodcast from "../assets/json/Message Broadcast.json";
import privatemessage from "../assets/json/Private Message.json";
import { Link } from "react-router-dom";
import host from "../host";

export const Home = () => {
  return (
    <div>
      <HeaderPage />

      <section className="home-wrapper-area testbanner">
        <Swiper
          pagination={{ clickable: true }}
          modules={[Pagination]}
          className="banner mySwiper"
        >
          <SwiperSlide className="bgheight bg1 ">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div
                    className="  testbanner-content   "
                    data-aos="fade-right"
                    data-aos-duration="2000"
                  >
                    <h1>
                      <span className=" ">
                        Do you struggle with hiring the best talent in a
                        competitive world and get tired of using traditional
                        tools that waste your recruitment and HR capacity?
                      </span>
                    </h1>
                    <p>
                      Use Skillwise.AI to create and administer tests that
                      assess the skills and qualifications of job candidates.
                      Leads to organizational cost savings, an increase in
                      capacity, accelerated hiring of the right talent and
                      internal stakeholder satisfaction.
                    </p>
                    <div
                      className="banner-btn mt-0 "
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <button
                        type="button"
                        className="btn  btn-primary"
                        onClick={() =>
                          (window.location.href =
                            host.selectright_marketing + "/book-demo")
                        }
                      >
                        Book A Demo
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div
                    className="banner-image  testlandingimage   "
                    data-aos="fade-left"
                    data-aos-duration="2000"
                  >
                    <img src={torch} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="bgheight bg1 ">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div
                    class="  testbanner-content "
                    data-aos="fade-right"
                    data-aos-duration="3000"
                  >
                    <h1>
                      <span class=" ">
                        Elevate your workforce with Skillwise.AI - Employee
                        training and certification made easy.
                      </span>
                    </h1>
                    <p>
                      Use Skillwise.AI to create and administer tests that
                      measure employee knowledge and skills. This is an amazing
                      tool for organizational TNI.
                    </p>
                    <div
                      class="banner-btn mt-0 "
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <Link to="/book-demo" className="d-none d-lg-block">
                        <button
                          type="button"
                          className="btn  btn-primary"
                          onClick={() =>
                            (window.location.href =
                              host.selectright_marketing + "/book-demo")
                          }
                        >
                          Book a demo
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div
                    className="banner-image  testlandingimage   "
                    data-aos="fade-left"
                    data-aos-duration="3000"
                  >
                    <img src={workforce} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="bgheight bg1 ">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div
                    class="  testbanner-content "
                    data-aos="fade-right"
                    data-aos-duration="2000"
                  >
                    <h1>
                      <span class=" ">
                        Achieve professional excellence with self created tests
                        and state of art proctoring services
                      </span>
                    </h1>
                    <p>
                      {" "}
                      Provide certification exams for professionals in a variety
                      of fields, and use the proctoring features to ensure exam
                      integrity.{" "}
                    </p>
                    <div
                      class="banner-btn mt-0 "
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <Link to="/book-demo" className="d-none d-lg-block">
                        <button
                          type="button"
                          className="btn  btn-primary"
                          onClick={() =>
                            (window.location.href =
                              host.selectright_marketing + "/book-demo")
                          }
                        >
                          Book a demo
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div
                    className="banner-image  testlandingimage   "
                    data-aos="fade-left"
                    data-aos-duration="2000"
                  >
                    <img src={proctoring} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="bgheight bg1 ">
            <div className="container">
              <div className="row align-items-center justify-content-center">
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div
                    class="  testbanner-content "
                    data-aos="fade-right"
                    data-aos-duration="2000"
                  >
                    <h1>
                      <span class=" ">
                        {" "}
                        Create and administer tests for students and
                        professionals alike in a variety of subjects, at all
                        levels of education{" "}
                      </span>
                    </h1>
                    <p> </p>
                    <div
                      class="banner-btn mt-0 "
                      data-aos="fade-up"
                      data-aos-duration="2000"
                    >
                      <Link to="/book-demo" className="d-none d-lg-block">
                        <button
                          type="button"
                          className="btn  btn-primary"
                          onClick={() =>
                            (window.location.href =
                              host.selectright_marketing + "/book-demo")
                          }
                        >
                          Book a demo
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
                  <div
                    className="banner-image  testlandingimage   "
                    data-aos="fade-left"
                    data-aos-duration="2000"
                  >
                    <Lottie
                      animationData={administer}
                      loop={true}
                      className="ctalottie"
                    />
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>
      <section className=" sectionpadding Introducing  ">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div
                className="intro-content"
                data-aos="fade-right"
                data-aos-duration="2000"
              >
                <h2>
                  Introducing <br />
                  Next-Gen AI-Powered <br />{" "}
                  <span className="text-blue-white">Test Platform!</span>
                </h2>
                <p>
                  Our AI-powered test platform comes with an easy-to-use
                  interface with which any academic institute or corporate
                  office can efficiently conduct an online test at their
                  convenience. It assists you to measure on the job skills,
                  personality, and behavior of a particular candidate. An
                  interviewer can easily create a test by using our numerous
                  pre-built questions available within our library and customize
                  it according to your needs. Furthermore, you can split the
                  assessments into multiple sections and send invites to bulk
                  candidates with ease. The new age assessment tools integrated
                  with our test platforms include a code compiler. It enables
                  you to create dynamic test assessments at once.
                </p>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12">
              <div
                className="banner-image  testlandingimage   "
                data-aos="fade-left"
                data-aos-duration="2000"
              >
                <img src={testall} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sectionpadding    createtest">
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-md-12 col-lg-10"
              data-aos="fade-up"
              data-aos-duration="2000"
            >
              <div className="section-title">
                <h2> Create Test </h2>
                <p>
                  Through our easy-to-use test platform before conducting any
                  exam, be it an academic exam-oriented test or an interview
                  conducted by fortune 500 companies, you can create tests as
                  per your requirements.
                </p>
              </div>
            </div>
          </div>
          <div className="create-test-box">
            <div className="test-cta fromlibrary">
              <div
                className="testcta-vactor  mblibrary"
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <img src={Library} alt="" />
                <div className="coming-soon">
                  <span>Being populated</span>
                  <img src={comingsoon} height={60} alt="" />
                </div>
              </div>
              <div
                className="test-cta-content"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h3>From Library</h3>
                <p>
                  {" "}
                  You can seamlessly create tests in one shot through our
                  dynamic library, which features around 100,000+ pre-built
                  questions.{" "}
                </p>
              </div>
            </div>
            <div className="test-cta from-custome  ">
              <div
                className="testcta-vactor "
                data-aos="zoom-in"
                data-aos-duration="2000"
              >
                <Lottie
                  animationData={Custome}
                  loop={true}
                  className="customejson"
                />
              </div>
              <div
                className="test-cta-content"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h3>Customized</h3>
                <p>
                  You can create tests with your own questions and easily
                  customize the tests according to your requirements; you can
                  even set a timer for a particular test according to your
                  standardized rules and regulations. An organization can also
                  customize its exam or interview questions at any time as per
                  their liking.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sectionpadding questions">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12 col-lg-10  ">
              <div
                className="section-title "
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h2>Add questions with texts and images </h2>
                <p>
                  Through our easy-to-use test platform, you can easily create
                  numerous MCQ questions with multiple-choice answers and even
                  include a compiler for coding questions.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-6  col-lg-6 col-md-12 col-12">
              <div className="test-status-tab">
                <ul className="nav nav-tabs  ">
                  <li
                    className="nav-item"
                    data-aos="fade-up"
                    data-aos-duration="2000"
                  >
                    <a
                      className="nav-link  active"
                      data-bs-toggle="tab"
                      href="#MCQ"
                    >
                      <span className="tab-head"> MCQ (Single) Questions </span>
                      <div className="hide-content">
                        <p>
                          You can customize the MCQ question even with a single
                          correct answer from the multiple options available to
                          a particular candidate. Additionally, you can set a
                          timer for a specific question and the entire test
                          session as per your requirements.
                        </p>
                      </div>
                    </a>
                  </li>
                  <li
                    className="nav-item "
                    data-aos="fade-up"
                    data-aos-duration="4000"
                  >
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#Multiple"
                    >
                      <span className="tab-head"> MCQ (Multiple) </span>
                      <div className="hide-content">
                        <p>
                          One can customize their MCQ questions with multiple
                          correct answers as per their requirements. You can
                          even set a timer and grade marks for each question
                          according to the standardized rules set by your
                          organization or the educational institute for which
                          you work for.
                        </p>
                      </div>
                    </a>
                  </li>
                  <li
                    className="nav-item "
                    data-aos="fade-up"
                    data-aos-duration="6000"
                  >
                    <a className="nav-link  " data-bs-toggle="tab" href="#TITA">
                      <span className="tab-head">TITA </span>
                      <div className="hide-content">
                        <p>
                          If candidates sit for an interview or a
                          management-level exam, they sometimes face questions
                          in the Type in the Answer format. As a result, they
                          are given a limited space to explain the answer to
                          such questions. You can even set a timer for these
                          questions in our test platform and mark each of these
                          questions according to your requirements.
                        </p>
                      </div>
                    </a>
                  </li>
                  <li
                    className="nav-item "
                    data-aos="fade-up"
                    data-aos-duration="8000"
                  >
                    <a className="nav-link" data-bs-toggle="tab" href="#Essay">
                      <span className="tab-head"> Essay </span>
                      <div className="hide-content">
                        <p>
                          For essay-type questions, you can easily set up the
                          time limits and customize the word counts for each
                          question as per the guidelines set up by the interview
                          panels. Additionally, you can set timers for each
                          question.
                        </p>
                      </div>
                    </a>
                  </li>
                  <li
                    className="nav-item "
                    data-aos="fade-up"
                    data-aos-duration="10000"
                  >
                    <a className="nav-link" data-bs-toggle="tab" href="#Video">
                      <span className="tab-head"> Video </span>
                      <div className="hide-content">
                        <p>
                          You can also accept video answers captured through
                          webcams. Imagine how convenient it will be if the
                          candidate can answer screening questions at their
                          convenience and the you can review the same at yours!
                        </p>
                      </div>
                    </a>
                  </li>
                  <li
                    className="nav-item "
                    data-aos="fade-up"
                    data-aos-duration="12000"
                  >
                    <a className="nav-link" data-bs-toggle="tab" href="#Coding">
                      <span className="tab-head">
                        {" "}
                        Coding{" "}
                        <small
                          className="text-danger"
                          style={{ marginLeft: "10px", fontSize: "14px" }}
                        >
                          {" "}
                          (Coming Soon)
                        </small>
                      </span>
                      <div className="hide-content">
                        <p>
                          {" "}
                          Test candidate's coding skills with the support of our
                          compilers that support all major languages.
                        </p>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6  col-lg-6 col-md-12 col-12">
              {/* Tabs content */}
              <div className="tab-content h-100" id="v-pills-tabContent">
                <div className="tab-pane fade show active h-100" id="MCQ">
                  <div className="testtype-vactor">
                    <Lottie
                      animationData={mcqsingal}
                      loop={true}
                      className="questiontype"
                    />
                  </div>
                </div>
                <div className="tab-pane fade h-100 " id="Multiple">
                  <div className="testtype-vactor">
                    <Lottie
                      animationData={mcqmultiple}
                      loop={true}
                      className="questiontype"
                    />
                  </div>
                </div>
                <div className="tab-pane fade   h-100   " id="TITA">
                  <div className="testtype-vactor">
                    <Lottie
                      animationData={tita}
                      loop={true}
                      className="questiontype"
                    />
                  </div>
                </div>
                <div className="tab-pane fade  h-100" id="Essay">
                  <div className="testtype-vactor">
                    <Lottie
                      animationData={Essay}
                      loop={true}
                      className="questiontype"
                    />
                  </div>
                </div>
                <div className="tab-pane fade h-100 " id="Video">
                  <div className="testtype-vactor">
                    <Lottie
                      animationData={video}
                      loop={true}
                      className="questiontype"
                    />
                  </div>
                </div>
                <div className="tab-pane fade h-100 " id="Coding">
                  <div className="testtype-vactor">
                    <Lottie
                      animationData={coding}
                      loop={true}
                      className="questiontype"
                    />
                    <div className="coming-soon">
                      <img src={comingsoon} height={60} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sectionpadding Proctoring ">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 col-lg-10  ">
              <div
                className="section-title "
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h2> Proctoring </h2>
                <p>
                  Our test platform is the best online proctoring platform
                  through which you can easily detect fraudulent activities
                  during a particular exam. Whether the exam is conducted by a
                  corporate house or an academic institute, our AI-powered auto
                  proctoring can catch the fraudulent activities a candidate
                  performs.
                </p>
              </div>
            </div>
          </div>
          <div className="row  justify-content-center align-items-center">
            <div className="proctoring-box">
              <div className="Proctoring-cta  ">
                <div
                  className="Proctoring-image"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <Lottie
                    animationData={webcam}
                    loop={true}
                    className="proctoringimage"
                  />
                </div>
                <div
                  className="Proctoring-content"
                  data-aos="fade-up"
                  data-aos-duration="2000"
                >
                  <h3>Video Proctoring</h3>
                  <p>
                    Through the AI-powered video proctoring tool, the test
                    platform can seamlessly check students’ video feeds and
                    raise flags at the time of suspicious activity.
                  </p>
                </div>
              </div>
              <div className="Proctoring-cta ">
                <div
                  className="Proctoring-image"
                  data-aos="fade-up"
                  data-aos-duration="4000"
                >
                  <Lottie
                    animationData={audioproctoring}
                    loop={true}
                    className="proctoringimage"
                  />
                </div>
                <div
                  className="Proctoring-content"
                  data-aos="fade-up"
                  data-aos-duration="4000"
                >
                  <h3>Audio Proctoring</h3>
                  <p>
                    At times, through our test platform, invigilators can look
                    at any time and check students' audio feeds; if they find
                    any additional human voice in the background, they can
                    quickly raise flags and disqualify the candidature of a
                    particular student.
                  </p>
                </div>
              </div>
              <div className="Proctoring-cta  ">
                <div
                  className="Proctoring-image"
                  data-aos="fade-up"
                  data-aos-duration="6000"
                >
                  <Lottie
                    animationData={tabswitch}
                    loop={true}
                    className="proctoringimage"
                  />
                </div>
                <div
                  className="Proctoring-content"
                  data-aos="fade-up"
                  data-aos-duration="6000"
                >
                  <h3>Tab Switching</h3>
                  <p>
                    The test platform during the examination time remains
                    full-screen and is designed to prevent candidates from
                    switching the tab. At the same time, each tab switch sends a
                    warning to the candidate. If the candidates continue this
                    tab switching despite several warnings, the student
                    automatically gets logged out after three consecutive tab
                    switches.
                  </p>
                </div>
              </div>
              <div className="Proctoring-cta  ">
                <div
                  className="Proctoring-image"
                  data-aos="fade-up"
                  data-aos-duration="8000"
                >
                  <Lottie
                    animationData={copypast}
                    loop={true}
                    className="proctoringimage"
                  />
                </div>
                <div
                  className="Proctoring-content"
                  data-aos="fade-up"
                  data-aos-duration="8000"
                >
                  <h3>Copy and Paste</h3>
                  <p>
                    The test setter can restrict copy and paste in the test
                    window to avoid copying answers.
                  </p>
                </div>
              </div>
              <div className="Proctoring-cta ">
                <div
                  className="Proctoring-image"
                  data-aos="fade-up"
                  data-aos-duration="10000"
                >
                  <Lottie
                    animationData={geolocation}
                    loop={true}
                    className="proctoringimage"
                  />
                </div>
                <div
                  className="Proctoring-content"
                  data-aos="fade-up"
                  data-aos-duration="10000"
                >
                  <h3>Geo-location</h3>
                  <p>
                    At the time of any test assessment, the institute that
                    conducts such test can restrict or keep an eye on the IP
                    addresses used by the candidates based on location. This
                    feature is helpful during campus recruitment drives or
                    invites a candidate to take the test from a specific
                    location.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sectionpadding dashboard-section d-flex align-items-center ">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-10 col-lg-10">
              <div
                className="section-title text-center "
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h2> Comprehensive Dashboard</h2>
                <p>
                  Through our dynamic and easy-to-use dashboards, an examiner or
                  an interviewer can thoroughly evaluate the candidates and
                  compare performance.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center align-items-center">
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
              <div
                className="dashboard-cta "
                data-aos="fade-up"
                data-aos-duration="3000"
              >
                <Lottie
                  animationData={dashboard1}
                  loop={true}
                  className="dashboardimage"
                />
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
              <div
                className="dashboard-cta "
                data-aos="fade-up"
                data-aos-duration="5000"
              >
                <Lottie
                  animationData={dashboard2}
                  loop={true}
                  className="dashboardimage"
                />
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12">
              <div
                className="dashboard-cta "
                data-aos="fade-up"
                data-aos-duration="7000"
              >
                <Lottie
                  animationData={dashboard3}
                  loop={true}
                  className="dashboardimage"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="sectionpadding    Report-tab-section">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-md-10 col-lg-10">
              <div
                className="section-title text-center"
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h2>Detailed Test Report </h2>
                <p>
                  It is the perfect test platform that enables you to judge the
                  performance and merits of every candidate at various levels.
                  Thus, the performance of every candidate is evaluated and
                  published in a comprehensive test report.
                </p>
              </div>
            </div>
          </div>
          <div className="flex-card ">
            <div className="card " data-aos="fade-up" data-aos-duration="2000">
              <div className="feature-content">
                <div className="feature-img-holder futurebg1">
                  <div className="bg-danger-soft feature-img-wrap">
                    <Lottie
                      animationData={Overall}
                      loop={true}
                      className="reportctaimg"
                    />
                  </div>
                </div>
                <div className="section-heading">
                  <h3>Overall Percentage and Percentile</h3>
                  <p>
                    The overall percentage and percentile that a particular
                    candidate secures enable a corporate organization or an
                    academic institute to understand the candidate’s strengths
                    and weaknesses at various levels.
                  </p>
                </div>
              </div>
            </div>
            <div className="card " data-aos="fade-up" data-aos-duration="4000">
              <div className="feature-content">
                <div className="feature-img-holder futurebg2">
                  <div className="bg-danger-soft feature-img-wrap">
                    <Lottie
                      animationData={Strengths}
                      loop={true}
                      className="reportctaimg"
                    />
                  </div>
                </div>
                <div className="section-heading">
                  <h3>Strength and Weakness</h3>
                  <p>
                    The comprehensive report that comes out once you finish your
                    test reflects the weaknesses and strengths of a particular
                    candidate who appeared for this examination.
                  </p>
                </div>
              </div>
            </div>
            <div className=" card" data-aos="fade-up" data-aos-duration="6000">
              <div className="feature-content">
                <div className="feature-img-holder futurebg1">
                  <div className="bg-danger-soft feature-img-wrap">
                    <Lottie
                      animationData={time}
                      loop={true}
                      className="reportctaimg"
                    />
                  </div>
                </div>
                <div className="section-heading">
                  <h3>Start and End Time</h3>
                  <p>
                    This platform allows you to customize the test according to
                    your requirements and set the start and end times of each
                    trial according to your requirements.
                  </p>
                </div>
              </div>
            </div>
            <div className="card " data-aos="fade-up" data-aos-duration="8000">
              <div className="feature-content">
                <div className="feature-img-holder futurebg2">
                  <div className="bg-danger-soft feature-img-wrap">
                    <Lottie
                      animationData={Frauds}
                      loop={true}
                      className="reportctaimg"
                    />
                  </div>
                </div>
                <div className="section-heading">
                  <h3>Frauds</h3>
                  <p>
                    Through this test platform, you can generate fraud reports
                    if any candidate passes the test using unethical means and
                    methods.
                  </p>
                </div>
              </div>
            </div>
            <div className="card" data-aos="fade-up" data-aos-duration="10000">
              <div className="feature-content">
                <div className="feature-img-holder futurebg1">
                  <div className="bg-danger-soft feature-img-wrap">
                    <Lottie
                      animationData={Certificates}
                      loop={true}
                      className="reportctaimg"
                    />
                  </div>
                </div>
                <div className="section-heading ">
                  <h3>Credible and Automated Certificates </h3>
                  <p>
                    Once a candidate completes the test, it instantaneously
                    generates credible and automated certificates.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" sectionpadding feature-tab-section   ">
        <div className="container">
          <div className="row justify-content-center align-content-center">
            <div className="col-md-12 col-lg-10  ">
              <div
                className="section-title text-center "
                data-aos="fade-up"
                data-aos-duration="2000"
              >
                <h2>Other Features </h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-15">
              <div
                className="feature-tab-info card "
                data-aos="fade-up"
                data-aos-duration="4000"
              >
                <div className="feature-tab-head">
                  <Lottie
                    animationData={settime}
                    loop={true}
                    className="reportctaimg"
                  />
                </div>
                <div className="feature-tab-body">
                  <h3>
                    {" "}
                    Set time limit <br />
                    Section-wise and Question-wise
                  </h3>
                  <p>
                    You can easily set up a time limit for all questions using
                    our easy-to-use test platform. Additionally, you can set up
                    a timer on a section-wise basis.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-15">
              <div
                className="feature-tab-info card "
                data-aos="fade-up"
                data-aos-duration="6000"
              >
                <div className="feature-tab-head">
                  <Lottie
                    animationData={livemonitoring}
                    loop={true}
                    className="reportctaimg"
                  />
                </div>
                <div className="feature-tab-body">
                  <h3>Live monitoring of test</h3>
                  <p>
                    You can virtually invigilate and monitor any candidate’s
                    test window while the test is on-going.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-15">
              <div
                className="feature-tab-info card "
                data-aos="fade-up"
                data-aos-duration="8000"
              >
                <div className="feature-tab-head">
                  <Lottie
                    animationData={brodcast}
                    loop={true}
                    className="reportctaimg"
                  />
                </div>
                <div className="feature-tab-body">
                  <h3> Message broadcast to test takers during a test</h3>
                  <p>
                    {" "}
                    During a particular exam, the invigilator or the interviewer
                    can easily broadcast messages through our test platform if
                    there is any common announcement that needs to be done.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 mt-15">
              <div
                className="feature-tab-info card "
                data-aos="fade-up"
                data-aos-duration="10000"
              >
                <div className="feature-tab-head">
                  <Lottie
                    animationData={privatemessage}
                    loop={true}
                    className="reportctaimg"
                  />
                </div>
                <div className="feature-tab-body">
                  <h3>Private message to any test taker during a test</h3>
                  <p>
                    {" "}
                    Invigilator can do a one-on-one chat with the candidate
                    while the test is on-going.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <FooterPage />
    </div>
  );
};
