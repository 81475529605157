import React, {  Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Home } from './pages/home'
import { Terms } from './pages/terms'
import { Privacy } from './pages/privacy'
import { JainRecording } from './pages/jgiproctoring'
 

const RoutesList = () => { 
    return (
        <Fragment>
            <Routes>
                <Route path='/' element={<Home />} /> 
                <Route path='/Terms' element={<Terms />} /> 
                <Route path='/privacy' element={<Privacy />} /> 
                <Route path='/jgiproctoring' element={<JainRecording />} /> 
            </Routes>

        </Fragment>
    )
}

export default RoutesList

