
import './App.css';
import { Fragment } from 'react'; 
import RoutesList from './Routes';
import MainPage from './_layout/main.page';
 
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min'; 
import '../src/assets/css/line-awesome.min.css'
import '../src/assets/css/style.css'
import '../src/assets/css/responsive.css'
 
// import AOS from 'aos';
//  import "aos/dist/aos.css"
//  AOS.init();
//  AOS.init({
 
//   duration: 2000, // values from 0 to 3000, with step 50ms
   

// });
function App() {
 
  return (
    <Fragment >
     <MainPage>
      <RoutesList /> 
      </MainPage>
    </Fragment>


  );
}

export default App;
