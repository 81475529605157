import React from 'react';
 
import systemcheck from "../assets/images/systemcheck.png";
import imgcheck from "../assets/images/imgcap.png";
import idcheck from "../assets/images/idcapture.png";
import logowblue from "../assets/images/jain-logo 1.png";
export const JainRecording  = () => {


  return (
    <div>

<header id="navigation" className="header">
          <div id="logo" className='text-center'>
            <img alt=''  id="main-logo" src={logowblue} height="40px" />
          </div>
        </header>
        <div className="extention-body" >
          <section className="section-pedding pt-3" id="section-system-check">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-10">
                  <div className="alert alert-primary text-center" role="alert">
                    Don't close this tab or refresh it for the duration of the Assessment
                  </div>
                </div>
                <div className="col-md-12 ">
                  <div className="step-headtitle text-center mt-3 mb-4">
                    <h3>How It Works</h3>
                    
                  </div>
                </div>
                <div className="col-md-4 bg-gray">
                  <div className="step-heading">
                    <span className="border-verticle borderbg" />
                    <div className="m-auto   numstep">1</div>
                    <h3 className="text-center mt-3 mb-3">System Check Before The Assessment</h3>
                    <div className="row justify-content-center">
                      <div className="col-md-6">
                        <div className="sec-image">
                          <img src={systemcheck} alt=''/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 bg-yellow">
                  <div className="step-heading">
                    <span className="border-verticle borderbg" />
                    <div className="m-auto   numstep">2</div>
                    <h3 className="text-center mt-3 mb-3">Image Check Before The Assessment</h3>
                    <div className="row justify-content-center">
                      <div className="col-md-6">
                        <div className="sec-image">
                          <img src={imgcheck} alt=''/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 bg-gray2">
                  <div className="step-heading">
                    <span className="border-verticle borderbg" />
                    <div className="m-auto   numstep">3</div>
                    <h3 className="text-center mt-3 mb-3">ID Check Before The Assessment</h3>
                    <div className="row justify-content-center">
                      <div className="col-md-6">
                        <div className="sec-image">
                          <img src={idcheck} alt=''/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
    </div>
  )
}
